import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Styles } from "./styles/pagination.js";

class Pagination extends Component {
    render() {
        return (
            <Styles>
                {/* Pagination */}
               { this.props.arrayLenght < 9 ?  <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>1</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul> : (this.props.arrayLenght > 9 && this.props.arrayLenght < 18) ? 
                     <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link><span onClick={this.props._starting(0,9)}>1</span></Link></li>
                    <li className="list-inline-item"><Link><span onClick={this.props._starting(9,9)}>1</span></Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul> : (this.props.arrayLenght > 18 && this.props.arrayLenght < 27) ?
                      <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(0,9)}>1</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(9,9)}>2</Link></li>
                    <li className="list-inline-item"><Link onClick={() => this.props._starting(18,9)}>3</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul> : (this.props.arrayLenght > 27 && this.props.arrayLenght < 36) ?
                      <ul className="pagination-box list-unstyled list-inline"> 
                <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-left"></i></Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>1</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>2</Link></li>
                     <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>3</Link></li>
                     <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>4</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-double-right"></i></Link></li>
                     </ul> : 
                     <ul className="pagination-box list-unstyled list-inline"> 
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>1</Link></li>
                     </ul>
           }

            </Styles>
        )
    }
}

export default Pagination
