import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/teamSlider.js";
import axios from 'axios';
import {Helmet} from "react-helmet";
import { FP } from "./css/demo.js";
import './css/jquery.flipster.min.css';
import { Link } from 'react-router-dom';

import * as $ from 'jquery';
import moment from 'moment';

const url = 'https://adminkto.stechomeyazilim.info';


class TeamSlider extends Component {

             constructor() {

            super();
        this.state = {
            arrayEvent: [],
            currentDate:"",
        };
    }
    


     async componentDidMount() {


  this.setState({
currentDate: moment().format('YYYY-MM-D')
                  }, async() => {
                               await this._starting();

                  })




}


_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/event/select/null/0/9')
      .then(res => {
        
                    this.setState({
arrayEvent: res.data
                  }, () => {
                       
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }
}

    render() {
        const settings = {
            slidesPerView: 4,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                992: {
                    slidesPerView: 4
                }
            }
        };

        return (
            <Styles>
 { this.state.arrayEvent.length > 0 && (
             <Helmet>

             <link
  type="text/css"
  rel="stylesheet"
  href="vendors/bootstrap/css/bootstrap.css"/>

  <script type = "text/javascript" >
var coverflow = $("#coverflow").flipster();
</script> 

            
            </Helmet>   )}







                {/* Team Slider */}
                <section className="team-member-area">
                    <Container>

                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>Etkinlikler ve Duyurular</h4>
                                </div>
                            </Col>

                           


                            <Col md="12" className="team-slider">
<FP>
 <body class="bodyEvent">

 <div id="coverflow">
        <ul class="flip-items">

                            { this.state.arrayEvent.length > 0 && (

     this.state.arrayEvent.slice(0,6).map((data, i) => (   

                                                                            
 this.state.currentDate < data.Event_Date_Start.substring(0,10) ?

            <li key={i}  data-flip-title={`category${i}`} data-flip-category= {data.FlipCategory} >
                <img className="img-content" src={url + data.Event_Image} />

                <div className="img-contentNew text-center">
                                              <Link to={{pathname: `/etkinlik-detay/${(data.Event_Title).replace(/\s+/g, '-')}`, state: { ID: data.ID }}}>

                     <h5>{data.Event_Title}</h5>
                     <p>{data.Event_Short_Text}</p>
                        

             <Link to={{pathname: `/etkinlik-detay/${(data.Event_Title).replace(/\s+/g, '-')}`, state: { ID: data.ID }}}>
             <li style = {{ fontSize: 16, fontWeight  : 500}} className="list-inline-item"><i className="las la-map-marker"></i>{data.Event_Location}</li>
             </Link>
                                                       
                     </Link>
                                                                            </div>
            </li>: 
             <li style = {{opacity:0.2}}key={i}  data-flip-title={`category${i}`} data-flip-category= {data.FlipCategory} >
                <img className="img-content" src={url + data.Event_Image} />

                <div className="img-contentNew text-center">
                                              <Link to={{pathname: `/etkinlik-detay/${(data.Event_Title).replace(/\s+/g, '-')}`, state: { ID: data.ID }}}>

                     <h5>{data.Event_Title}</h5>
                     <p>{data.Event_Short_Text}</p>
                        

             <Link to={{pathname: `/etkinlik-detay/${(data.Event_Title).replace(/\s+/g, '-')}`, state: { ID: data.ID }}}>
             <li style = {{ fontSize: 16, fontWeight  : 500}} className="list-inline-item"><i className="las la-map-marker"></i>{data.Event_Location}</li>
             </Link>
                                                       
                     </Link>
                                                                            </div>
            </li> 
            

         
                                                                    ))
                                                                        
                                                                
                                                           )}

                                                            </ul>

<h6 className="h6Class">*Kampanyalardan faydalanmak için Teknokent çalışanı olma koşulu vardır.</h6>

                            
</div>

 <h6 className="ButtonClass"><Link to={process.env.PUBLIC_URL + "/events"}>Tüm Gelişmeler</Link></h6>
                                                  

</body>


</FP>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TeamSlider
