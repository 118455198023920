import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const FP = styled.div`


.bodyEvent {

background: transparent url(../img/patterntimage.png) repeat;

  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 20px;
margin:0 auto; 
}


h6.h6Class {
   margin: auto;
                              padding-top:30px;
                                color: ${colors.green};
text-align:center;
                        a {
                           margin: auto;
                        color         : white;
                        padding: 10px;
                            font-weight  : 100;
                            margin-bottom: 12px;

                            &:hover {
                                color: ${colors.green};
                            }
                        }
                    }

  h6.ButtonClass {

   margin: auto;
   padding-top:30px;
   text-align:center;

                        a {
                             border: 1px solid rgba(255, 255, 255, 255);

                           margin: auto;
                        color         : white;
                        padding: 10px;
                        background-color:    ${colors.black1};
                            font-weight  : 100;
                            margin-bottom: 12px;

                            &:hover {
                                color: ${colors.green};
                            }
                        }
                    }
 .img-contentNew {
                    background: #ffffff;
                    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.07);
                    position  : absolute;
                    bottom    : -50px;
                    left      : 10%;
                    width     : 80%;
                    z-index   : 1;
                    border-radius : 5px;
                    padding: 15px 0;

                    h5 {
                        color        : red;
                        font-weight  : 600;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size    : 14px;
                        color        : gray;
                        font-weight  : 500;
                        margin-bottom: 5px;
                    }

                    ul {
                        li {
                            a {
                                i {
                                    font-size: 14px;
                                    color    : #ffffff;
                                    width    : 33px;
                                    height   : 33px;
                                    border-radius : 50%;
                                    padding-top: 10px;
                                    transition : all 0.2s ease;

                                    &:hover {
                                        background-color: 'green' !important;
                                    }
                                }

                                i.fa-facebook-f {
                                    background-color: #4267B2;
                                }

                                i.fa-twitter {
                                    background-color: #1DA1F2;
                                }

                                i.fa-youtube {
                                    background-color: #DD1343;
                                }
                            }
                        }
                    }
                }
            

h2 { font-size: 22px; }

/* @end */



.intro {
  padding: 4em 2em;
}

.demo {
  padding: 6em 2em;
}

.demo h2 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 1em;
}

.demo .flipster {
  margin: 0 -2em;
}


 .img-content {
                    background: #ffffff;
                    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.07);
                    height     : 350px;
                    width     : 250px;
                    z-index   : 99;
                    border-radius : 5px;
                    padding: 15px 0;

}
.code {
  display: block;
  background: #eee;
  padding: 1em;
  border-radius: 1em;
  margin: 2em auto;
  max-width: 30em;
  font-size: 0.8em;
  width: fit-content;
  white-space: pre-wrap;
}

.button {
  display: inline-block;
  padding: 5px 10px;
  margin: 0;
  background-color: #da0d25;
  background-position: center center;
  background-repeat: no-repeat;
  color: #FFF;
  text-shadow: none;
  vertical-align: middle;
  cursor: pointer;
  border: 0;
  transition: all 300ms ease;
}

.button:hover,
.button:focus {
  color: #7a0715;
  background: #f22840;
}`;
