import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import Search from './common/Search';
import Sidebar from './common/Sidebar';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";
import axios from 'axios';
import {Helmet} from "react-helmet";

class HeaderTwo extends Component {
            constructor() {
        super();
        this.state = {
            ArrayProjectCategory: [],
          ArrayStudentCategory:[],

        };
    }
    
    async componentDidMount() {

        await this._starting();
}

_starting = async () => {

    await this._getProjectsCategory();
    await this._getStudentCategory();
    
}




_getStudentCategory= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/studentCategory/select')
      .then(res => {

  this.setState({
                ArrayStudentCategory : res.data

          }, () => {

          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }

_getProjectsCategory= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/projectsCategory/select/')
      .then(res => {

  this.setState({
                ArrayProjectCategory : res.data

          }, () => {

          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }

    render() {
        return (
            <Styles>

             <Helmet>
                <meta charSet="utf-8" />
          <script src="../menu.js"></script>
            </Helmet>

                {/* Topbar 2 */}
              <section className="top-bar2">
                    <Container>
                        <Row>
                            <Col lg="6" md="5">
                                <div className="bar-left">
                                  
                                </div>
                            </Col>
                            <Col lg="6" md="7">
                                <div className="bar-right d-flex justify-content-end">
                                    <ul className="list-unstyled list-inline bar-social">

                                    
                                        <li className="list-inline-item"><a target="_blank" href={process.env.PUBLIC_URL + "https://twitter.com/karataytto"}><i className="fab fa-twitter"></i></a></li>
                                               

                                                  </ul>
                                    <ul   className="list-unstyled list-inline bar-social2">
                                            <li className="nav-item search-box">
                                                <Search />
                                            </li>
                                            
   </ul>

                                    <ul className="list-unstyled list-inline bar-login">
                                                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><img style = {{width:30,height:30}} src={process.env.PUBLIC_URL + "/assets/images/iconlogo.png"} alt="" /></a></li>

                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/inavasyon"}>Fikir, Proje ve İnovasyon Alanı Rezervasyon</Link></li>

      </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area 2 */}
                <section className="logo-area2">
                    <Container>
                        <Row>
                            <Col md="3">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="" /></Link>
                                </div>
                            </Col>
                            <Col md="9">
                                <div className="menu-box d-flex justify-content-end">

                                 
                                    <ul className="nav menu-nav">
                                        <li className="nav-item dropdown active">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Anasayfa</Link>
                                          
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Hakkımızda <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/ekibimiz"}>Ekibimiz</Link></li>
                                              
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/tanitimmateryal"}>Tanıtım Materyalleri</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/tanitimvideo"}>Tanıtım Videosu</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/mevzuat"}>Mevzuat</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/mevzuat"}>Performans</Link></li>

                                           
                                            </ul>
                                        </li>




                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Projeler <i className="las la-angle-down"></i></Link>
                                           <ul className="dropdown list-unstyled">
                                             {
                                                        this.state.ArrayProjectCategory.map((data, i) => ( 
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + (data.Projects_Category_Name).replace(/\s+/g, '-').toLowerCase()}>{data.Projects_Category_Name}</Link></li>
                         ))
                                                    }
                                                                                            <li className="nav-item dropdown">

                                                     <ul id='menu'>
    <li className="nav-item"><a title='Menu'>Öğrenci Projeleri</a>
    
     <ul className='menus'>
    

  {
                                                        this.state.ArrayStudentCategory.map((data, i) => ( 
                                                       <li className='has-submenu'><Link to={{pathname: `/ogrenci-projeleri/${(data.Project_Category_Name).replace(/\s+/g, '-').toLowerCase()}`, state: { ID: data.ID }}}>{data.Project_Category_Name}</Link></li>

                         ))
                                                    }

                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/proje-yukle"}>Proje Yükleme</Link></li>

       
       </ul>
    </li>
  </ul>
    </li>                                           

     <li className="nav-item dropdown">

                                                     <ul id='menu'>
    <li className="nav-item"><a  title='Menu'>Proje Çağrıları</a>
    
     <ul className='menus'>
    
                                                       <li  className='has-submenu'><a target="_blank" href='https://www.tubitak.gov.tr/tr/destekler/akademik/ulusal-destek-programlari'>TÜBİTAK Çağrıları</a></li>

                   
                                                     <li><a target="_blank" href='https://h2020.org.tr/tr' title='Dropdown 3'>Ufuk 2020 AB Proje Destekleri</a></li>
     
                                                          <li><a target="_blank" href='https://www.kosgeb.gov.tr/site/tr/genel/destekler/3/destekler' title='Dropdown 3'>KOSGEB</a></li>
  

       </ul>
    </li>
  </ul>
    </li>     

                               

                     </ul>
                                        </li>
                                      
                                         <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Yayınlar <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><a className="nav-link" target="_blank" href='http://acikerisim.karatay.edu.tr' title='Dropdown 3'>DSPACE</a></li>
                                                <li className="nav-item"><a className="nav-link" target="_blank" href='http://ttopanel.karatay.edu.tr' title='Dropdown 3'>Yayın Teşvik Başvurusu</a></li>
                                           
                                            </ul>
                                        </li>
                                      
                                         <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/iletisim"} data-toggle="dropdown">İletisim</Link>
                                        </li>

                                                                               

                                    </ul>
                                   
                                    <div className="apply-btn">
                                        <Link to={process.env.PUBLIC_URL + "/etkinlikler"}><i className="las la-clipboard-list"></i>Etkinlikler</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
                <StickyMenu />

                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default HeaderTwo
