import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import {Helmet} from "react-helmet";

const html= ` <div class="menumain">

        <nav class="menunav" style="left:20px;">

            <ul class='circle'>

                <li class='almond light slice' style="transform: rotate( 49deg ) skewX( 40deg );">
                    <!-- Menu labels -->
                    <label for='almond' class='circle over' id="4"></label>
                </li>

                <li class='almond light slice'>
                    <!-- Menu labels -->
                    <label for='almond' class='circle over' id="14"></label>
                </li>
                <li class="coconut light slice" style="transform: rotate( 314deg ) skewX( 40deg ); z-index: 1;">
                    <!-- Menu labels -->
                    <label for='ococonuto' style="color:white;" class='circle over ' id="1"><!--ÖĞRENCİ--></label>
                </li>

                <li class="coconut light slice" style="z-index:1;">
                    <!-- Menu labels -->
                    <label for='ococonuto' style="color:white;" class='circle over ' id="1"><!--ÖĞRENCİ--></label>
                </li>

                <li class='vanilla light slice' style="transform: rotate( 132deg ) skewX( 40deg );">
                    <label for='orange' style="color:white; z-index:3;" class='circle over ' id="2"><!--AKADEMİSYEN--></label>
                </li>
                <li class='vanilla light slice'>
                    <label for='orange' style="color:white; z-index:3;" class='circle over ' id="2"><!--AKADEMİSYEN--></label>
                </li>
                <li class='orange light slice' style="transform: rotate( 181deg ) skewX( 40deg ); }">
                    <label for='ovanilla' style="color: white;z-index: 3;" class='circle over ' id="3"><!--SEKTÖR TALEBİ--></label>
                </li>


                <li class='orange light slice'>
                    <label for='ovanilla' style="color: white;z-index: 3;" class='circle over ' id="3"><!--SEKTÖR TALEBİ--></label>
                </li>

                <li class="unsel circle" style="width: 245px;height: 245px;top: 110px;left: 105px;">
                    <label for="unsel" class="clicky menuname" style="margin-bottom: 5px;border-bottom-width: 0px;margin-top: 0px;padding-top: 5px;" id="8"><h1 style="position:relative; bottom:40px;font-weight:bold;">PROJE FİKRİ</h1></label>

                </li>
                <li class="unsel2 circle" style="width: 300px;height: 300px;top: 80px;left: 80px;">
                    <label for="unsel2" class="clicky menuname" style="font-size:25px"></label>
                </li>
                <!--<li class="unsel3 circle" style="width: 210px;height: 210px;top: 140px;left: 140px;">
        <label for="unsel2" class="clicky menuname" style="font-size:25px"></label>
    </li>-->
            </ul>
            <div style="
        box-shadow: 3px 3px 16px 5px #212121;
        position: relative;
        z-index: -2;
        width: 460px;
        height: 460px;
        background: #cecece;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        bottom: 470px;
        right: 5px;
        left: -5;
        left: -5;
        ">
            </div>
            <h7 style="position: relative;color: white;bottom: 600px;display: block;width: 200%;height: 200%;transform: skew(334deg) rotate(215deg);
line-height: 1.9;text-align: center;z-index: 5;bottom: 580px;right: 350px;font-weight: bold;" onclick="OGRENCI()">ÖĞRENCİ</h7>
            <h7 style=" position: relative;color: white;bottom: 700px;display: block;width: 200%;height: 200%; transform: skew(330deg) rotate(37deg); line-height: 1.9; text-align: center;bottom: 850px;right: 60px;
        font-weight: bold;
">AKADEMİSYEN</h7>
            <h7 style="position: relative;color: white;bottom: 500px;display: block;width: 200%;height: 200%;transform: skew(30deg) rotate(148deg);line-height: 1.9;text-align: center;bottom: 620px;right: 100px;font-weight: bold;">SEKTÖR TALEBİ</h7>
        </nav>


        <div class="w3-row" style=" position: relative;">

            <div class='hidden' id="menu2" style="position: relative;left: 500px; top: 100px;">
                <div class="dropdown4m " style=" position:relative; top:-180px; height:300px; width:1600px;">
                    <button class="dropbtnx" style="display:block;top: 530px;left: 510px;"></button>
                    <button id="myBtn4m" style="display: block; padding: 15px; margin-left: 508px;margin-top: 360px; font-family: cursive;" class="dropbtn4m"onClick="window.open('http://www.example.com');">AR-GE<br>ALTYAPI<br> DESTEĞİ</button>
 <div id="myDropdown4m" class="dropdown4m-content" style="top:-100px;"> </div>
                    <div style="position:relative; top:-200px;">
                        <hr class="ciz1" style="height: 2px;position: relative;z-index:-1;width: 700px;margin-left: -200px;left: 70px;">
                        <div style="position: relative;background-color: #F80;border: 3px solid #FFF;border-radius: 18px;box-shadow: 0 0 2px #888;height: 30px;width: 30px;margin-left: 220px;margin-top: -30px;left:-130px;"></div>
                        <div style="position: relative; background-color: #F80;border: 3px solid #FFF; border-radius: 18px;box-shadow: 0 0 2px #888;height: 30px; width: 30px;margin-left: 450px;margin-top: -30px; left:100px"></div>
                    </div>
                </div>
                <div class="dropdown4 " style="top: -220px; left: -150px;">
                    <button  style=" font-family: cursive; position: relative; left: 0px; bottom: 90px; padding: 15px;" class="dropbtn4">MENTÖR<br> DESTEĞİ</button>
                    <button class="dropbtnx" style="top: 0px;margin-left: 30px;"></button>
                    <div id="myDropdown4" class="dropdown4-content show4" style="
    bottom: 300px;
">

                        <div style="position:relative;margin-left: 130px;top: 250px;left: 5px;"></div>
                        <button class="dropbtn44" style="display: block;position: inherit;padding: 15px;left: 10px;bottom: 20px;top: 200px;"onClick="window.open('http://www.example.com');" >
                            PROJE<br> KABUL
                        </button>
                        <button id="myDIVkesik2" style="width: 512px;display: block;top: -30;left: 260px;position: relative;z-index: -2;bottom: 30px;top: 230px;" class="ciz11 answer_list"></button>
                        <button class="dropbtn444" style="display: block;position: relative;padding: 15px;font-family: cursive;left: 100px;top: -30;top: -30;bottom: 100px;top: -50;top: 120px;" onClick="window.open('http://www.example.com');">
                            PROJE <br>RET
                        </button>
                        <button  style="font-family: cursive;display: block;top: -10;left: 550px;padding: 15px;font-family: cursive;color:white;bottom: 20px;top: 260px;" class="dropbtn12n answer_list"> Yayın  Makale<br>Hazırlığı</button>
                        <button id="myDIV" style="display: block;position: relative;width: 0px;left: 75px;height: 182px;margin-top: -10px;margin-left: 180px;bottom: 0px;top: 160px;" class="dropbtn11 answer_list"> <p id="wht">Ar-Ge, Proje İnovasyon Faaliyetleri Kapsamında Teşvik Ödülü</p></button>
                        <button id="myDIV2" style="display: block;position: relative;height: 182px;left: -10;top: -100;bottom: 230px;top: -200;right: 10px;left: -10;top: 0px;" class="dropbtn12 answer_list"> <p id="wht"> Ar-Ge, Proje İnovasyon Faaliyetleri Kapsamında Teşvik Ödülü</p> </button>
                        <button id="oklar2" style="display: block;left: 680px;height: 107.98438px;top: -50;bottom: -20;top: -100;top: -100;width: 185.99306px;top: -10;top: 20px;" class="oklar2 answer_list"></button>
                        <button id="myDIVarmut" class="dropbtn12m2 answer_list" style="display: block;left: 900px;bottom: 0px;top: 0px;"onClick="window.open('http://www.example.com');"><p id="wht"> Biyoistatistik<br> Analiz Desteği</p></button>
                        <!--<button id="myDIVkesik" style="  display: block;left: 130px;position: relative; z-index: -2;" class="ciz11 answer_list"></button>-->
                        <button id="myDIV3o" style="display: block;bottom:300px;right: 50px;left: -50;margin-left: 230px;" class="dropbtn12o answer_list"><p id="wht"> Lisansüstü Bursiyer Desteği<br> Kısmi Zamanlı Çalışan<br>Öğrenci Desteği</p></button>
                        <button id="myDIVelma" style="top: -350;left: 580px;display: block;position: relative;bottom: 200px;z-index:1; top: -400;top: -350;" class="dropbtn12m1 answer_list"onClick="window.open('http://www.example.com');"><p id="wht"> Makale-Dil<br> Redaksiyonu<br> Desteği</p></button>
                        <button id="dot1" style="display: block;z-index: 1;position: relative;background-color: orange;border: 3px solid #FFF;border-radius: 18px;box-shadow: 0 0 2px #888;height: 30px;width: 30px;margin-left: 450px;margin-top: -30px;left: 240px;top: -90;bottom: 185px;top: -80;"></button>

                        <button id="dot2" style="position: relative;background-color: #F80;border: 3px solid #FFF;border-radius: 18px;box-shadow: 0 0 2px #888;height: 30px;width: 30px;margin-left: 480px;margin-top: -30px;left: 500px;top: -215px;"></button>
                        <button id="oksol" style=" display: block;" class="ciz0 answer_list"></button>
                        <button id="mydıvelmaoklar" style="        display: block;" class="oklar answer_list"></button>
                        <button id="myDIVelmay" style="position: relative; display: block; left: 500px;" class="dropbtn12m11 answer_list"onClick="window.open('http://www.example.com');"><p id="wht"> Yayın Ret</p></button>
                        <button id="myDIVelmak" style="position: relative; display: block;" class="dropbtn12m12 answer_list" onClick="window.open('http://www.example.com');"><p id="wht">  Yayın Kabul</p></button>
                        <button id="myDIVkesik3" style="position: relative;display: block;position: relative;z-index:-1;top: -360;left: 850px;bottom: 360px;" class="ciz33 answer_list"></button>
                        <button id="myDIVmuz" style="        position: relative;
        display: block;
        left: 790px;
        top: -410px;
        height: 102px;" class="dropbtn12a answer_list">
                            <p id="wht"> Yayın Teşvik Ödülü</p>
                        </button>
                        <button id="myDIVkesik4" style=" z-index: -1;display: block;position: relative" class="ciz34 answer_list"></button>
                        <button id="myDIVelmaye" style="display: block;top: -600;left: 860px;bottom: 600px;" class="dropbtn12m121 answer_list"onClick="window.open('http://www.example.com');"><p id="wht"> Kongre<br> Sempozyum<br> Katılım Desteği</p></button>
                        <button id="myDIVelmakı" style="display:block; " class="dropbtn12m122 answer_list"onClick="window.open('http://www.example.com');"><p id="wht">  Makale Atıfı</p></button>
                        <button id="myDIVmuz2" style="display: block; height: 102px;" class="dropbtn12b answer_list"><p id="wht">Atıf Teşvik Ödülü</p></button>
                        <button id="patent" class="dropbtn12m answer_list" style="font-family: cursive;display: block;position: absolute;left: 500px;width: 99px;height: 99px;top: 390px;"onClick="window.open('http://www.example.com');"> Patent<br> Başvurusu</button>
                        <button id="myDIV3" style="display: block;position: relative;width: 142px;left: 460px;height: 212px;margin-top: -10px;margin-left: 10px;bottom: 1200px;padding-left: 10px;padding-right: 10px;padding-bottom: 10px;border-left-width: 20px;" class="dropbtn19 answer_list"> <p id="wht"><b>DESTEK</b><br>Patent Süreçleri Desteği<br> Hizmet Buluşu Desteği</p></button>
                        <div id="mmm" style="display: block;position: absolute;z-index: 1;top: 260px;left: 420px;height: 114px;width: 162px;"></div>
                    </div>
                </div>

            </div>

            <div class='hidden ' id="menu1" style="position:relative; left: 500px; width:600px; top:400px;">
                <!-- Menu content, hidden by default -->
                <hr class="ciz3" style="position: relative;margin-top:-30px;width: 800px;height: 2px;margin-left: -200px;z-index: -1;left: -1left: -100px;left: -100;right: 90px;left: -50;left: -50;">
                <div style="background-color: #F80;border: 3px solid #FFF;border-radius: 18px;box-shadow: 0 0 2px #888;height: 30px;width: 30px;margin-left: 88px;margin-top: -33px;"></div>
                <div style="background-color: #F80; border: 3px solid #FFF;display: block;border-radius: 18px; box-shadow: 0 0 2px #888;height: 30px; width: 30px;margin-left: 473px;margin-top: -30px;"></div>
                <button class="dropbtn2 " style="        font-family: cursive; display: block;position: relative;top: -50px;left: 500px;background-color: #ffffff; padding-top: 15px; padding-bottom: 15px; padding-right: 20px;padding-left: 20px;" onClick="window.open('https://kto.stechomeyazilim.info/inavasyon');">
                    <img style="width:50px;" src="img/unnamed.png" />
                </button>
                <!--<button id="myBtn22" style="display: block;top: -60px;left: 500px; height:190px;" class="dropbtn99999 "><p id="wht">Üniversitemiz Öğrencilerimize Yönelik Fikir, Proje ve İnovasyon Alanı</p></button>-->
                <button id="myBtn22" style="display: block;top: -60px;left: 480px;height:190px;width: 149px;" class="dropbtn99999 float-left submit-button"><p id="wht">Üniversitemiz Öğrencilerimize Yönelik Fikir, Proje ve İnovasyon Alanı</p></button>
                <button  class="dropbtn"  onClick="window.open('http://www.example.com');"style="display: block; position: relative; top: -350px; width: 170px; height: 150px;"><p style="margin-top:-15px; font-size:13px;">Lisans Projeleri<br>  ve Bitirme Tezleri <br> Destekleme Programı</p></button>
                <button class="dropbtnx3" style="display: block; position: relative; left: 135px; top: -460px;"></button>
                <div class="dropdown2">
                    <div id="myDropdown" class="dropdown-content " style="display: block; left: -450px; top: -550px;">
                        <div></div>
                        <button id="13" class="dropbtn5" onclick="myFunction13()" style="display: block;padding-top: 10px;padding-bottom: 10px;padding-right: 20px;padding-left: 20px;"> TÜBİTAK<br>2209A/B<br>Proje<br>Kabul</button>
                        <button id="DIV4" class="dropbtn55" onclick="myFunction14()" style="margin-top: -92px;margin-right: -25px;display: block;margin-left: 250px;padding-top: 15px;padding-bottom: 15px;padding-right: 25px;padding-left: 25px; position:relative; bottom:35px;"> TÜBİTAK<br>2209A/B<br>Proje<br>Ret</button>
                        <button id="myDIV3" style="position: relative;height: 190px;display: block;top: -30;z-index: -1;bottom: 50px;" class="dropbtn9999 answer_list">
                            <p id="wht" style="text-align:center"> Ar-Ge, Proje İnovasyon Faaliyetleri Kapsamında Teşvik Ödülü</p>
                        </button>
                        <button id="myDIV4" style="display: block;height: 190px;top: -100;z-index: -1;position: relative;left: 35px;bottom: 240px;top: -200;" class="dropbtn10 answer_list">
                            <p id="wht"> Ar-Ge, Proje İnovasyon Faaliyetleri Kapsamında Teşvik Ödülü</p>
                        </button>
                     

                    </div>
                </div>
             
            </div>


            <div class='hidden' id="menu3" style="position: relative;left: 400px;height: 1100px;">
                <hr class="ciz1" style="height: 2px;margin-left: -260px;width: 800px;position: relative;top: 300px;left: 40px;z-index: -1;">
                <div style="position: relative;background-color: #F80;border: 3px solid #FFF;border-radius: 18px;box-shadow: 0 0 2px #888;height: 30px;width: 30px;margin-left: 50px;margin-top: -330px;top: 590px;left: 50px;">
                </div>

                <div class="dropdown3 " style=" top: 0px;">

                    <button class="dropbtnx2" style="left: 530px;top: 550px;"></button>
                    <button class="dropbtn4m" onclick="window.open('http://www.example.com');" style=" font-family: cursive; position: relative; padding: 15px; display: block; left: 430px; ">AR-GE<br> AltYapı<br> Desteği</button>
                    <button class="ora answer_list" id="yayın3" style="display: block;position: relative;left: 755px;top: 0px;height: 107.98438px;"></button>
                    <button id="arge1" class="dropbtn12m answer_list" style="font-family: cursive;display: block;top: 80px;left: 550px;">Patent<br>Başvurusu</button>
                    <button id="arge2" class="dropbtn12n answer_list" style="padding:15px;display: none;top: 120px;left: 750px;">Yayın Makale <br>Hazırlığı</button>
                    <button class="ora answer_list" id="yayın3" style="display: block;position: relative;left: 600px;top: 85px;"></button>
                    <button id="yayın2" onclick="window.open('http://www.example.com');" class="dropbtn12m2 answer_list" style="display: none;top: 125px;position:relative;left: 1100px;">Biyoistatistik <br> Analiz Desteği</button>
                    <button id="yayın1" onclick="window.open('http://www.example.com');" class="dropbtn12m1 answer_list" style="display: block;position: relative;left: 930px;top: -60;bottom: 80px;top: -60;top: -80;">Makale Dil<br> Redaksiyonu Desteği</button>

                    <button id="Ret" class="dropbtn12m11 answer_list" style="display: block;top: 65px;left: 1100px;">Yayın Ret</button>
                    <button id="Kabul" onclick="window.open('http://www.example.com');" class="dropbtn12m12 answer_list" style="display: block;top: 0px;left: 900px;">Yayın Kabul</button>
                    <button id="Kabul2" class="dropbtn12a answer_list " style="display: block;top: 0px;left: 960px;color: white;">Yayın Teşvik Ödülü</button>
                    <button id="atıf" class="dropbtn12m121 answer_list" style="display: block;top: 30px;left: 880px;">Makale Atıfı</button>
                    <button id="atıf29" onclick="window.open('http://www.example.com');" class="dropbtn12c answer_list" style="display: block;top: -700px;left: 815px;color: white;bottom: 0px;top: -600px;margin-top: 240px;border-top-width: 30px;"><b>DESTEK</b><br>Patent Süreçleri Desteği<br>Hizmet Buluşu Desteği</button>
                    <button id="atıf3" onclick="window.open('http://www.example.com');" class="dropbtn12m121 answer_list" style="display: none;top: -230px;left: 1025px; z-index:-2">Kongre Sempozyum<br> Katılım Desteği</button>
                    <button id="myDIVkesik3" style="  display: block; position: absolute;top: -270px;left: 1200px; z-index: -1" class="ciz33 answer_list"></button>
                    <button id="atıf2" class="dropbtn12c answer_list" style="display: block;top: -500;left: 960px;color: white;bottom: 450px;margin-top: 30px;margin-bottom: 50px;top: -250;">Atıf Teşvik Ödülü</button>
                    <button id="myBtn3" onclick="window.open('https://kto.stechomeyazilim.info/iletisim');" class="dropbtn3" style="  position: absolute;  padding: 15px;top: 700px;left: 520px; display: block; bottom: 650px;height: 102px;font-family: cursive; ">
                        Akademik<br> Danışmanlık
                    </button>
                    <button class="dropbtn555" onclick="window.open('http://www.example.com');" style="padding:15px; position: absolute;left: 520px;top: 650px;display: block;bottom: 550px;height: 100px;">
                        İŞ BİRLİĞİ
                    </button>
                    <button id="myDIV5" style="position: absolute;display: block;left: 490px;top: 770px;height: 302px; padding:0px 60px 10px 20px;" class="dropbtn9 answer_list">
                        <p style="color:white"> KARSEM<br> Konya Teknokent <br> Innopark <br> Karatay TTO <br> Sektör <br> Akademisyen </p>
                    </button>
                    <button id="myDIVelmaye" style="display: block;top: -600;left: 760px;bottom: 600px;" class="dropbtn12m121 answer_list" onclick="window.open('http://www.example.com');"><p id="wht"> Kongre<br> Sempozyum<br> Katılım Desteği</p></button>
                    <button class="ora answer_list" id="Ret0" style="display: none;position: relative;left: 750px;top: 50px;"></button>
                </div>
            </div>


            <div class='visible' id="menu8">
            

            </div>

        </div>
    </div>
`
class Menu extends Component {
    constructor() {
        super();
        this.state = {
        };
    }
    
    async componentDidMount() {


}

render(){
    return (
  <div className="main-wrapper contact-page">
         <Helmet>
                <meta charSet="utf-8" />
    
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>

      <script src="./javaScript.js"></script>
    <script src="./menu2.js"></script>

     <link href="https://www.jqueryscript.net/css/jquerysctipttop.css" rel="stylesheet" type="text/css"/>
    <link href='https://fonts.googleapis.com/css?family=Quicksand' rel='stylesheet' type='text/css'/>
    <link href="./menucss/style.css" type="text/css" rel="stylesheet"/>
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"/>

            </Helmet>

          

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="İletişim" />

                {/* Contact Area */}
                                             






                <div  dangerouslySetInnerHTML={{__html: html}} />


 

            </div>
    )
}
}
export default Menu