import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import BlogSidebar from './components/BlogSidebar';
import CommentForm from './components/CommentForm';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/blogDetails.js';
import axios from 'axios';

import base64 from 'react-native-base64'
import ReactHtml from 'raw-html-react';
import {Helmet} from "react-helmet";

const url = 'https://adminkto.stechomeyazilim.info';


class BlogDetails extends Component {
        constructor() {

            super();
        this.state = {
            arrayNews: [],
        feed: [],
        };
    }
    


     async componentDidMount() {

        await this._starting();

}
  async componentWillUpdate() {

        await this._starting();

}


_starting = async () => {

     try{
     
     await axios.get(`https://kto.stechomeyazilim.info:5000/news/select/${this.props.location.state.ID}`)
      .then(res => {
        
         res.data.map((data, i) => (
                                          
                                           this.setState({
BlogImage: data.News_Image,
BlogHtml: (new Buffer(data.News_Long_HTML, 'base64')).toString('utf8'),
News_Date_Created: (data.News_Date_Created).substring(0,10),
News_Title: data.News_Title,
News_Short_Text: data.News_Short_Text
                  }, () => {
                      

                                       
 
                  })

                                        ))

                   
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}

                <Helmet>
                <meta charSet="utf-8" />
               
            </Helmet>

                <div className="main-wrapper blog-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title= "Haber Detay" />

                    {/* Blog Details */}
                    <section className="blog-details-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="8" sm="7">
                                    <div className="blog-details-box">
                                        <div className="blog-details-banner">
                                            <img src={url + this.state.BlogImage} alt="" className="img-fluid" />
                                        </div>
                                        <div className="heading">
                                            <h4>{this.state.News_Title}</h4>
                                        </div>
                                        <div className="blog-auth_date d-flex">
                                            <div className="author-img d-flex">
                                                <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + `/assets/images/author.jpg`} alt="" /></Link>
                                                <p><Link to={process.env.PUBLIC_URL + "/"}>{this.state.News_Short_Text}</Link></p>
                                            </div>
                                            <div className="post-date">
                                                <p><i className="las la-calendar"></i> {this.state.News_Date_Created}</p>
                                            </div>
                                          
                                        </div>
                                        <div className="blog-details-desc">
                                            <ul className="list-unstyled">


                                             <ReactHtml html={this.state.BlogHtml} />


                                </ul>
                                        </div>
                                        <div className="blog-tag_share d-flex justify-content-between">
                                            <div className="blog-tag">
                                                <ul className="tags list-unstyled list-inline">
                                                    <li className="list-inline-item">Tags:</li>
                                                  
                                                </ul>
                                            </div>
                                            <div className="blog-share">
                                                <ul className="social list-unstyled list-inline">
                                                    <li className="list-inline-item">Share:</li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                       

                                    </div>
                                </Col>
                                <Col lg="3" md="4" sm="5">
                                    <BlogSidebar />
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default BlogDetails