import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Datas from '../../../data/blog/recent-post.json';
import { Styles } from '../styles/recentPost.js';
import axios from 'axios';
const url = 'https://adminkto.stechomeyazilim.info';

class RecentPost extends Component {
     constructor() {

            super();
        this.state = {
            arrayNews: [],

        };
    }

         async componentDidMount() {




        await this._starting();

}

_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/news/select')
      .then(res => {
        
                    this.setState({
arrayNews: res.data
                  }, () => {
                      

                                       
 
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}

    render() {
        return (
            <Styles>
                {/* Recent Post */}
                <div className="recent-blog">
                    <h5>Son Haberler</h5>
                    <div className="blog-items">
                        {
                            this.state.arrayNews.slice(0,3).map((data, i) => (

                                <div className="item-box d-flex" key={i}>
                                    <div className="item-img">
                                        <Link to={{pathname: `/haber-detay/${(data.News_Title).replace(/\s+/g, '-').toLowerCase()}`, state: { ID: data.ID }}}><img src={url + data.News_Image} alt="" /></Link>
                                    </div>
                                    <div className="item-content">
                                        <p className="title"><Link to={{pathname: `/haber-detay/${(data.News_Title).replace(/\s+/g, '-').toLowerCase()}`, state: { ID: data.ID }}}>{data.News_Title}</Link></p>
                                        <span className="date">{(data.News_Date_Created).substring(0,10)}</span>
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                </div>
            </Styles>
        )
    }
}

export default RecentPost
