import React from 'react';
import { BrowserRouter as Router, Switch, Route,HashRouter } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import About from './pages/about/About';
import CourseGrid from './pages/courses/CourseGrid';
import CourseList from './pages/courses/CourseList';
import CourseDetails from './pages/courses/CourseDetails';
import Instructor from './pages/instructor/Instructors';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import ProjectUpload from './pages/projectupload/ProjectUpload';
import Projects from './pages/projects/Projects';
import DevamProje from './pages/projects/devamedenprojelerimiz';
import TamamProje from './pages/projects/tamamlananprojelerimiz';



import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';
import Inavasyon from './pages/inovasyon/Inovasyon';


import Iletisim from './pages/iletisim/Contact';
import Menu from './pages/menu/Menu';

import OgrenciProjeleri from './pages/ogrenciprojeleri/Project';

import TanıtımVideo from './pages/tanıtım/TanıtımVideo';
import TanıtımMateryal from './pages/tanıtım/TanıtımMateryal';
import Mevzuat from './pages/tanıtım/Mevzuat';
import HaberDetay from './pages/inovasyon/BlogDetails';


function App() {
    return (
<HashRouter>
            <GlobalStyle />
            <ScrollToTop />
            <Switch>
                <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={HomeOne} />
                <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo} />
                <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={About} />
                <Route path={`${process.env.PUBLIC_URL + "/course-grid"}`} component={CourseGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/course-list"}`} component={CourseList} />
                <Route path={`${process.env.PUBLIC_URL + "/course-details"}`} component={CourseDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/ekibimiz"}`} component={Instructor} />
                <Route path={`${process.env.PUBLIC_URL + "/ekibimiz-detay"}`} component={InstructorDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} component={Gallery} />
                <Route path={`${process.env.PUBLIC_URL + "/etkinlikler"}`} component={Events} />
                <Route path={`${process.env.PUBLIC_URL + "/etkinlik-detay"}`} component={EventDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/proje-yukle"}`} component={ProjectUpload} />
                <Route path={`${process.env.PUBLIC_URL + "/projelerimiz"}`} component={Projects} />
                <Route path={`${process.env.PUBLIC_URL + "/404"}`} component={PageNotFound} />
                <Route path={`${process.env.PUBLIC_URL + "/coming-soon"}`} component={ComingSoon} />
                <Route path={`${process.env.PUBLIC_URL + "/inavasyon"}`} component={Inavasyon} />
              
                <Route path={`${process.env.PUBLIC_URL + "/haber-detay"}`} component={HaberDetay} />
                <Route path={`${process.env.PUBLIC_URL + "/devam-eden-bilimsel-projelerimiz"}`} component={DevamProje} />
                <Route path={`${process.env.PUBLIC_URL + "/tamamlanan-bilimsel-projelerimiz"}`} component={TamamProje} />
                <Route path={`${process.env.PUBLIC_URL + "/ogrenci-projeleri"}`} component={OgrenciProjeleri} />
                <Route path={`${process.env.PUBLIC_URL + "/iletisim"}`} component={Iletisim} />
                <Route path={`${process.env.PUBLIC_URL + "/menu"}`} component={Menu} />

                <Route path={`${process.env.PUBLIC_URL + "/tanitimvideo"}`} component={TanıtımVideo} />
                <Route path={`${process.env.PUBLIC_URL + "/tanitimmateryal"}`} component={TanıtımMateryal} />

                <Route path={`${process.env.PUBLIC_URL + "/mevzuat"}`} component={Mevzuat} />


            </Switch>
</HashRouter>
    )
}

export default App;