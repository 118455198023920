import React, { Component } from 'react';
import Datas from '../../data/blog/classic.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Pagination from './../../components/Pagination';
import BlogSidebar from './components/BlogSidebar';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/blog.js';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import { CheckBox } from 'devextreme-react/check-box';
import notify from 'devextreme/ui/notify';
import AppointmentTooltip from './AppointmentTooltip.js';
import Appointment from './Appointment.js'
import axios from 'axios';
import Query from 'devextreme/data/query';
import randomColor from "randomcolor";



import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import  './styles.css';

import moment from 'moment';


import { data, moviesData, theatreData } from './data.js';



const currentDate = new Date();

const views = ['day', 'week', 'timelineDay'];



const groups = ['ID'];





const url =
  "https://kto.stechomeyazilim.info/apiweb2/RestController.php?view=getSaloon";


const urlReservation =
  "https://kto.stechomeyazilim.info/apiweb2/RestController.php?view=getReservation";


class Inovasyon extends Component {
 constructor(props) {
    super(props);
    this.state = {
      allowAdding: true,
      allowDeleting: true,
      allowResizing: true,
      allowDragging: true,
      allowUpdating: true,
      saloonArray:[],
      reservationArray:[],
    };

        this.onAppointmentFormOpening = this.onAppointmentFormOpening.bind(this);
    this.showAddedToast = this.showAddedToast.bind(this);


  }

    async componentDidMount() {

        await this._getSaloon();
        await this._getReservation();

}


    async getMovieById(id) {
  return Query(this.state.reservationArray).filter(['ID', id]).toArray()[0];
}








 _getReservation = async () => {
    try {
      axios.get('https://kto.stechomeyazilim.info:5000/reservation/select/').then((res) => {

 res.data.map((data, i) => (

this.setState(prevState => ({
  reservationArray: [...prevState.reservationArray,{ID : data.ID, Reservation_Reason: data.Reservation_Reason, startDate:  new Date(data.startDate) , endDate: new Date(data.endDate), Reservation_Saloon_ID : data.Reservation_Saloon_ID , text: data.Reservation_NameSurname}],  

}))

                                        ))
      console.log("reservationAr123ray" + this.state.reservationArray[0]);


      });
    } catch (error) {
      console.log("error" + error);
    }
  };


    _getSaloon = async () => {
    try {


      axios.get('https://kto.stechomeyazilim.info:5000/saloon/select/').then((res) => {

         res.data.map((data, i) => (

this.setState(prevState => ({
  saloonArray: [...prevState.saloonArray,{"id": data.SID, "text": data.Reservation_Saloon_NAME}]
}))

                                        ))

      });
    } catch (error) {
      console.log("error" + error);
    }
  };


    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper blog-classic-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Fikir, Proje ve İnovasyon Alanı Rezervasyon" />

                    {/* Blog Classic */}
                    <section className="blog-classic-area">
                        <Container>
                            <Row>
                                
                                   
  { this.state.reservationArray.length > 0 ?  <Scheduler
          timeZone="Turkey"
          dataSource={this.state.reservationArray}
          views={views}
          defaultCurrentView="day"
          defaultCurrentDate={currentDate}
          groups={groups}
          height={600}
          firstDayOfWeek={0}
          startDayHour={9}
          endDayHour={23}
          showAllDayPanel={false}
          crossScrollingEnabled={true}
          cellDuration={20}
          editing={{ allowAdding: true }}
                appointmentComponent={Appointment}
               onAppointmentAdded={this.showAddedToast}
        appointmentTooltipComponent={AppointmentTooltip}
          onAppointmentFormOpening={this.onAppointmentFormOpening}
        >
    
          <Resource
            dataSource={this.state.saloonArray}
            fieldExpr="ID"
                      useColorAsDefault={false}
          />
        </Scheduler> : null}
                              
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }

 showAddedToast(e) {

var color = randomColor();

     const data = {
     Reservation_StartDateTime: e.appointmentData.startDate, 
     Reservation_EndDateTime: e.appointmentData.endDate,
     Reservation_NameSurname: e.appointmentData.text,
     Reservation_Reason: e.appointmentData.Reservation_Reason,     
     Reservation_Saloon_ID: e.appointmentData.SaloonID,
     Reservation_Color:color

  };


fetch("https://kto.stechomeyazilim.info:5000/reservation/insert", {
  method: "POST",
  body: JSON.stringify(data),
  headers: { "Content-Type": "application/json" }
})
.then(res =>       this.showToast('KTO TTO', "Rezervasyon İşleminiz Başarıyla Gerçekleşmiştir, Lütfen Onay Bekleyiniz", 'success'))
.catch(err => console.log(err))

  }


 onAppointmentFormOpening(data) {
    let form1 = data.appointmentData;
    let form = data.form;

    form.option('items', [{
      label: {
        text: 'Salon'
      },
      dataField: 'SaloonID',
      editorType: 'dxSelectBox',
      editorOptions: {
        value: form1.Reservation_Saloon_ID,
        items: this.state.saloonArray,
        displayExpr: 'text',
        valueExpr: 'id',
        onValueChanged: function(args) {
         // form.updateData('director', movieInfo.director);
        //  form.updateData('endDate', new Date(startDate.getTime() + 60 * 1000 * movieInfo.duration));
        }
      },
    }, {
      label: {
        text: 'Adınız Soyadınız'
      },
      dataField: 'text',
      name: 'Reservation_NameSurname',
      editorType: 'dxTextBox',
      editorOptions: {
        value: form1.text,
        readOnly: false
      }
    }, {
      label: {
        text: 'Rezervasyon Nedeniniz'
      },
      dataField: 'Reservation_Reason',
      name: 'Reservation_Reason',
      editorType: 'dxTextBox',
      editorOptions: {
        value: form1.Reservation_Reason,
        readOnly: false
      }
    }, {
      dataField: 'startDate',
      editorType: 'dxDateBox',
      editorOptions: {
        width: '100%',
        type: 'datetime',
        onValueChanged: function(args) {
        }
      }
    }, {
      name: 'endDate',
      dataField: 'endDate',
      editorType: 'dxDateBox',
      editorOptions: {
        width: '100%',
        type: 'datetime',
      }
    }
    ]);
  }




  showToast(event, value, type) {
    notify(`${event} "${value}"`, type, 1800);
  }



  showUpdatedToast(e) {
    this.showToast('Updated', e.appointmentData.text, 'info');
  }

  showDeletedToast(e) {
    this.showToast('Deleted', e.appointmentData.text, 'warning');
  }
}

export default Inovasyon