  import React, { Component } from 'react';
import { Styles } from '../styles/courseCategory.js';


class CourseCategory extends Component {
     constructor() {

            super();
        this.state = {
        arrayLesson:[],
        };
    }
    


         async componentDidMount() {


}

    render() {
        return (
            <Styles>
                {/* Course Tag */}
                <div className="course-category">
                    <h5>KTO TTO</h5>
                    <ul className="category-item list-unstyled">

                    
                                                <li className="check-btn">
                            <a href="cat1">Ekibimiz</a>

                        </li>
                                         <li className="check-btn">
                            <a href="cat1">Tanıtım Materyalleri</a>
                        </li>
                                                  
                                                             <li className="check-btn">
                            <a href="cat1">Tanıtım Videoları</a>
                        </li>

                                                                 <li className="check-btn">
                            <a href="cat1">Mevzuat</a>
                        </li>

                                                                 <li className="check-btn">
                            <a href="cat1">Performans</a>
                        </li>                    

                       
                    
                    </ul>
                </div>
            </Styles>
        )
    }
}

export default CourseCategory
