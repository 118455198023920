import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/heroSlider.js";
import axios from 'axios';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"


// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper/core';


const url="https://adminkto.stechomeyazilim.info"

SwiperCore.use([Autoplay,Pagination,Navigation]);


class HeroSlider extends Component {
  constructor() {
        super();
        this.state = {
            arraySlider: [],
        
        };
    }
    


     async componentDidMount() {

        await this._starting();


}

_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/slider/select')
      .then(res => {
        
                    this.setState({
arraySlider: res.data
                  }, () => {
                       
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}


    render() {
        const settings = {
                  
            slidesPerView: 1,
           
            speed: 500,
    loop: true,
    mousewheel: true,
    autoPlay: {
        delay: 2000
    },
            watchSlidesVisibility: true,
            effect: 'fade',
            navigation: {
                nextEl: '.slider-button-next',
                prevEl: '.slider-button-prev'
            },
            renderPrevButton: () => (
                <div className="swiper-btn slider-button-prev"><i className="flaticon-arrow-left-th"></i></div>
            ),
            renderNextButton: () => (
                <div className="swiper-btn slider-button-next"><i className="flaticon-arrow-right-th"></i></div>
            )
        };


        return (
            <Styles>
                {/* Hero Slider */}
                <section className="hero-slider-area">
<div class="rectangle">
    <div class="trapezoid">
    <img style={{  position: 'absolute', left:5, top:5}} width="60" height="40" src={process.env.PUBLIC_URL + `/assets/images/logo_.png`} className="slider-image" />
    <img style={{position: 'absolute', right:5, top:5}} width="50" height="40" src={process.env.PUBLIC_URL + `/assets/images/logoorta.png`} className="slider-image" />
    </div>
                                        


    

</div>

 <Swiper spaceBetween={30} centeredSlides={true} autoplay={{
  "delay": 2500,
  "disableOnInteraction": false
}} pagination={{
  "clickable": true
}} navigation={true} className="mySwiper">

{ this.state.arraySlider.map((data, i) => (
        <SwiperSlide>

                                <div className="slider-item" key={i}>
                                    <div className="image-container">
                                        <img style={{ height: '100%', width: '100%', objectFit: 'contain'}} src={url +  `${data.SliderImage}`} className="slider-image" alt={data.SliderImage} />
                                    </div>
                                    
                                </div>
                                     </SwiperSlide>

                            ))}

  </Swiper>

 </section>
            </Styles>
        )
    }
}

export default HeroSlider
