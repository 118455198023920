import React from 'react';
import Query from 'devextreme/data/query';

import { moviesData } from './data.js';

function getMovieById(id) {
  return Query(moviesData).filter(['id', id]).toArray()[0];
}

export default function Appointment(model) {
  const { appointmentData } = model.data;
 


  return (
    <div className="showtime-preview">
      <div> <a style = {{color:'white', fontSize:14,  fontFamily: 'Arial'}}>Öğrenci Adı: {appointmentData.text}</a></div>
      <div> <a style = {{textAlign:'center',color:'white', fontSize:13,  fontFamily: 'Arial'}}>Neden: {appointmentData.Reservation_Reason}</a></div>


      <div>
      
      </div>
    </div>
  );
}
