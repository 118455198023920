import React, { Component } from 'react';
import Datas from '../data/course/slider.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/courseSlider.js";
import axios from 'axios';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { Styles1 } from "./styles/iconBox.js";

let Parser = require('rss-parser');
let parser = new Parser();

const url = 'https://adminkto.stechomeyazilim.info';

class TubitakSlider extends Component {
     constructor() {

            super();
        this.state = {
        feed: []
        };
    }
    


     async componentDidMount() {


await this._starting()


}


_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/tubitak/select/')
      .then(res => {

  

   res.data.rss.channel.forEach(item => {

this.setState(prevState => ({
  feed: [...item.item, ...prevState.feed]
}))



  });

      })


    }
    catch (error) {
      
      console.log("error" + error)
    }


}

    render() {
        const settings = {
            slidesPerView: 3,
            loop: true,
            speed: 2000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        };

        return (
            <Styles>
                {/* Course Slider */}
                <section className="course-slider-area">
                    <Container>
                        <Row>
                           
                            <Col md="12" className="course-slider">
                            <div class="course-slider-left-tubitak">

     { this.state.feed.length > 3 && (

                                <Swiper {...settings}>
                                    {
                                        this.state.feed.map((data, i) => (


                                          <div className="course-item" key={i}>
                                                    <div className="course-image" style={{ justifyContent: "center",
  alignItems: "center"
 }}>
                                                                <img class="centerImage" src={"https://pbs.twimg.com/profile_images/1116582435053981697/fvuFt4P4_400x400.png"} alt="" />
                                                    
                                                    </div>
                                                     
       <a target="_blank" href={data.link}>
                                                   
                                                </a>
                                                <div className="course-content">
                                                    <h6 className="heading"><a target="_blank" href={data.link}>{data.title}</a></h6>
                                                   {data.pubDate != null ? <p className="desc">{(data.pubDate).toString().substring(0, 22)}</p> : null}
                                                   
<Styles1>

                {/* Icon Box */}
                <section className="icon-box-area">
                    
                     
                                   
                                        <div className="full-icon-box">
                                            <div className="icon-box d-flex">
                                                <div className="box-icon box2">
                                               <i className="flaticon-clipboard"></i>
                                                </div>
                                                <a   style = {{color:'gray',  fontWeight  : 600}}  target="_blank" href={data.link}>Detaya Git</a>

                                            </div>
                                        </div>
                                   
                       
                </section>
          


</Styles1>

                                                    

                                                </div>


                                               
                                            </div>
                                        ))
                                    }
                                </Swiper> )}





                        <h6 className="Button"><a href="https://www.tubitak.gov.tr" target="_blank">Tüm Gelişmeler</a></h6>

                                </div>

                            </Col>


                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TubitakSlider
