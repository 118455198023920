import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Timer from 'react-compound-timer';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import PopularCourse from './../courses/components/PopularCourse';
import CourseTag from './../courses/components/CourseTag';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/eventDetails.js';
import axios from 'axios';
import base64 from 'react-native-base64'
import ReactHtml from 'raw-html-react';
import Modal from 'react-bootstrap/Modal'

import { Styles1 } from './styles/event2.js';

const url = 'https://adminkto.stechomeyazilim.info';




class EventDetails extends Component {
         constructor() {

            super();
        this.state = {
            arrayDetailEvent: [],
        showModal:false,
        };
    }
    


     async componentDidMount() {

        await this._starting();
}


setShowModal =  (value) => {
    if (value == true){
         this.setState({
            showModal:true
        })
    }
      
    if (value == false){
         this.setState({
            showModal:false
        })
    }
}



   
           onFormSubmit = async(e) => {


             const form = document.getElementById("form_contact");
        const Name1 = document.getElementById("Name");
        const Mail_Adress1 = document.getElementById("Mail_Adress");
        const Contact_Number1 = document.getElementById("Contact_Number");

          e.preventDefault();
    var Name = this.Name.value.trim();
    var Mail_Adress = this.Mail_Adress.value.trim();
    var Contact_Number = this.Contact_Number.value.trim();



            if (Name === "") {
                this.setError(Name1, "İsim Alanı Boş Geçilmez");
            } else {
                this.setSuccess(Name1);
            }
        
            if (Mail_Adress === "") {
                this.setError(Mail_Adress1, "Mail Adresinizi Boş Bırakamazsınız");
            } else if (!this.isEmail(Mail_Adress)) {
                this.setError(Mail_Adress1, "Yanlış Format");
            } else {
                this.setSuccess(Mail_Adress1);
            }

      if (Contact_Number === "") {
                this.setError(Name1, "İletişim Numarası Boş Geçilemez");
            } else {
                this.setSuccess(Contact_Number1);
            }

            e.preventDefault();
     
 const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
      const data = {
     Application_NameSurname: Name, // This is the body part
     Application_MailAdress: Mail_Adress,
     Application_PhoneNumber: Contact_Number,

     Event_ID: this.props.location.state.ID,
  

  };


    fetch("https://kto.stechomeyazilim.info:5000/event/insert/", {
  method: "post",
  body: JSON.stringify(data),
  headers: { "Content-Type": "application/json" }
})
.then(res => alert("Etkinliğe katılma talebiniz başarıyla oluşturulmuştur."))
.catch(err => alert("Etkinliğe katılma talebiniz oluşturulurken bir hata oluşty."))

    
  
      
    }


        setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".contact_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
        }

         setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

         isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }



_prevEvent = async () => {
     try{
     

     await axios.get(`https://kto.stechomeyazilim.info:5000/event/prevSelect/${this.props.location.state.ID}`)
      .then(res => {
        
                    res.data.map((item) => {
                this.setState({
                    PrevID:item.ID,
                    PrevEvent_Title :  item.Event_Title,
                    PrevEvent_Image:item.Event_Image,
                    PrevEvent_Date_Start: item.Event_Date_Start.substring(0,10),
                 

                      }, async() => {

                      })
              })

      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}


_nextEvent = async () => {
     try{
     

     await axios.get(`https://kto.stechomeyazilim.info:5000/event/nextSelect/${this.props.location.state.ID}`)
      .then(res => {
        
                    res.data.map((item) => {
                this.setState({
                    NextID:item.ID,
                    NextEvent_Title :  item.Event_Title,
                    NextEvent_Image:item.Event_Image,
                    NextEvent_Date_Start: item.Event_Date_Start.substring(0,10),
                 

                      }, async() => {
                      })
              })

      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}

_starting = async () => {

     try{
     

     await axios.get(`https://kto.stechomeyazilim.info:5000/eventDetail/select/${this.props.location.state.ID}`)
      .then(res => {
        
                    res.data.map((item) => {
                this.setState({
                    ID:item.ID,
                    Event_Title :  item.Event_Title,
                    Event_Long_HTML :  base64.decode(item.Event_Long_HTML),
                    Event_Image:item.Event_Image,
                    Event_Date_Start: item.Event_Date_Start.substring(0,10),
                    Event_Location: item.Event_Location,
                    Kontenjan:item.Kontenjan,
                    Email_Adress:item.Email_Adress,
                    Website_Url:item.Website_Url,
                    Event_Date_Created: item.Event_Date_Created.substring(0,10),
                    Topics: item.Topics,
                    Event_Image_Detail:item.Event_Image_Detail,
                    Event_Organization: item.Event_Organization,
                      }, async() => {

                        await this._nextEvent();
                        await this._prevEvent();

                      })
              })

      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-details-page">

 <Modal
        show={this.state.showModal}
        onHide={() =>this.setShowModal(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
           Etkinlik Başvuru Formu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Styles1>
          <section className="contact-area">

               

 
                  
                                <div className="contact-form">
                                   
                                    <div className="form-box">
                                        <form id="form_contact" className="form">
                                            <Row>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input  ref={(c) => this.Name = c} type="text" placeholder="Adınız Soyisiminiz" id="Name" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                              
                                               <Col md="6">
                                                    <p className="form-control">
                                                        <input ref={(c) => this.Mail_Adress = c} id="Mail_Adress" placeholder="Mail Adresiniz" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>


 <Col md="12">
                                                    <p className="form-control">
                                                        <input ref={(c) => this.Contact_Number = c} id="Contact_Number" placeholder="İletişim Numaranız" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                               
                                                <Col md="12">
                                                    <button style = {{marginTop:20}} onClick={this.onFormSubmit}>Formu Gönder</button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                      

                    {/* Google Map */}
                 
                </section>
 </Styles1>
        </Modal.Body>

      </Modal>

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Etkinlik Detayı" />

                    {/* Event Details Area */}
                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="8" sm="12">
                                    <div className="event-details-content">
                                        <div className="heading">
                                            <h4>{this.state.Event_Title}</h4>
                                        </div>
                                        <div className="event-icon">
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item"><i className="las la-calendar"></i> {this.state.Event_Date_Created}</li>
                                                <li className="list-inline-item"><i className="las la-map-marker"></i> {this.state.Event_Location}</li>
                                                <li className="list-inline-item"><i className="las la-copy"></i>{this.state.Topics}</li>
                                            </ul>
                                        </div>
                                        <div className="event-details-banner">
                                            <img src={url + `${this.state.Event_Image_Detail}`} alt="" className="img-fluid" />
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Etkinlik Detay</h5>
                                           <ReactHtml html={this.state.Event_Long_HTML}/>
                                        </div>
                                       

                                        <div className="pre-nxt-event">
                                      
                                            <Row>
                                              {this.state.PrevEvent_Title != null ?
                                                <Col md="6">
                                                    <div className="next-prev-item prev-event d-flex">
                                                        <div className="prev-img">
                                                            <img src={url + this.state.PrevEvent_Image} alt="" />
                                                        </div>
                                                        <div className="prev-text">
                                                            <p><Link  to={{pathname: process.env.PUBLIC_URL + "/etkinlik-detay/" + (this.state.PrevEvent_Title).replace(/\s+/g, '-'), state: { ID: this.state.PrevID }}}>{this.state.PrevEvent_Title}</Link></p>
                                                            <span>{this.state.PrevEvent_Date_Start}</span>
                                                        </div>
                                                    </div>
                                                </Col> : null }
                                        {this.state.NextEvent_Title != null ?
                                                <Col md="6">
                                                    <div className="next-prev-item next-event d-flex flex-row-reverse text-right">
                                                        <div className="next-img">
                                                            <img src={url + this.state.NextEvent_Image} alt="" />
                                                        </div>
                                                        <div className="next-text">
                                                            <p><Link to={{pathname: process.env.PUBLIC_URL + "/etkinlik-detay/" + (this.state.NextEvent_Title).replace(/\s+/g, '-'), state: { ID: this.state.NextID }}}>{this.state.NextEvent_Title}</Link></p>
                                                            <span> {this.state.NextEvent_Date_Start}</span>
                                                        </div>
                                                    </div>
                                                </Col>: null }
                                            </Row> 
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="3" md="4" sm="12">
                                    <div className="event-details-sidebar">
                                   { this.state.Event_Date_Start != null ?
                                                                           <Row>
                                                                               <Col md="12">
                                                                                   <div className="event-sidebar-info">
                                                                                       <div className="event-sidebar-timer text-center">
                                                                                           <Timer initialTime={ Math.floor((new Date(this.state.Event_Date_Start).getTime() - new Date().getTime()))} direction="backward">
                                                                                               <p><Timer.Days /><span>Gün</span></p>
                                                                                               <p><Timer.Hours /><span>Saat</span></p>
                                                                                               <p><Timer.Minutes /><span>Dakika</span></p>
                                                                                           </Timer>
                                                                                       </div>
                                                                                       <ul className="list-unstyled event-info-list">
                                                                                           <li>Başlangıç Tarihi: <span>{this.state.Event_Date_Start}</span></li>
                                                                                           <li>Zaman: <span>08:30am</span></li>
                                                                                           <li>Kontenjan: <span>{this.state.Kontenjan}</span></li>
                                                                                           <li>Konum: <span>{this.state.Event_Location}</span></li>
                                                                                           <li>Organizasyon: <span>{this.state.Event_Organization}</span></li>
                                                                                           <li>Mail: <span>{this.state.Email_Adress}</span></li>
                                                                                           <li>Web: <span>{this.state.Website_Url}</span></li>
                                                                                       </ul>
                                                                                       <button onClick={() => this.setShowModal(true)} type="button" className="buy-btn">Etkinliğe Katıl</button>
                                                                                   </div>
                                                                               </Col>
                                                                               
                                                                           </Row> : null}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default EventDetails